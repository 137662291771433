.home {
    .base-form {
        padding: 0 1rem;
        .form-group {
            position: relative;
            &.form-number {
                span {
                    display: block;
                    text-align: right;
                    width: 100%;
                    border: 1px solid rgb(216, 216, 216);
                    border-radius: 5px;
                    padding: 1rem 1rem 1rem 4rem;
                    line-height: 1;
                    font-size: 6vw;
                    color: rgb(0, 0, 0);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &:hover {
                        border: 1px solid #23ac38;
                    }
                    i {
                        font-size: .5em;
                        padding: 0 5px;
                    }
                }
                input {
                    pointer-events: none;
                    opacity: 0;
                }
                >i {
                    // content: '金额';
                    position: absolute;
                    left: 1rem;
                    top: 50%;
                    transform: translate(0, -50%);
                    color: #bbb;
                    font-size: 4vw;
                    font-weight: 100;
                }
            }
        }
        .tips {
            display: block;
            text-align: center;
            font-size: 3vw;
            /* line-height: 2rem; */
            margin-top: 10px;
        }
    }
    .btn-box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 4vh 20px;
        span {
            display: block;
            text-align: center;
            font-size: 3vw;
        }
        i {
            display: block;
            @include bg-dif(no-repeat, center center, 60%);
            @include size(20vw);
            background-color: #09bb07;
            border-radius: 100%;
            overflow: hidden;
            margin-bottom: 20px;
            padding: 20px;
            @media (max-width: 500px) {
                padding: 10px;
            }
        }
        >div {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            flex-basis: 50%;
            &.qr {
                i {
                    // background-image: url('../assets/images/qr.svg');
                }
            }
            &.scan {
                i {
                    // background-image: url('../assets/images/scan.svg');
                    padding: 12px;
                    @media (min-width: 500px) {
                        padding: 22px;
                    }
                }
            }
        }
    }
    &.on {
        .jk-keyboard,
        .btn-box {
            opacity: 0;
        }
    }
}

.paylogo {
    padding: 5vw 2vw;
    border-bottom: 1px solid #ddd;
    transition: .5s all ease;
    position: relative;
    .inner {
        display: flex;
        justify-content: space-around;
        margin: auto;
        width: 90%;
        div {
            text-align: center;
            flex-basis: 50%;
            display: flex;
            flex-direction: column;
            img {
                height: 5vw;
                margin: auto;
            }
            span {}
            .price {
                font-size: 3vw;
                b {
                    font-size: 4vw;
                }
            }
            .tips {
                font-size: 2vw;
            }
        }
    } // &.on {
    //     transform: translate(0,0);
    //     width: 100%;
    //     z-index: 999;
    //     background-color: #eee;
    // }
}

.qrbox {
    h2 {
        // padding: 10px;
        font-size: 3vw;
        position: absolute;
        bottom: 20vw;
    }
    .bg {
        @include size(100%);
        position: absolute;
    }
    .qr-img {
        pointer-events: none;
    }
    .inner {
        z-index: 1;
        @include size(100%);
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        >div {
            text-align: center;
            border-radius: 3vw;
            display: flex;
            flex-direction: column;
            width: 60%;
            max-width: 300px;
            .qr-inner {
                padding: 4vw;
                .qr-logo {
                    padding: 2vw 3vw 0;
                }
                .qr-box {
                    // border: 1px solid #ddd;
                    // border-radius: 8px;
                    position: relative;
                    padding: 10px;
                    margin: 10% auto;
                    background-color: #fff;
                    width: 70%;
                    span {
                        font-size: 4vw;
                    }
                    >div {
                        position: relative;
                        span {
                            display: block;
                        }
                        >img {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: 20%;
                            transform: translate(-50%, -50%);
                            pointer-events: none;
                        }
                    }
                }
            }
        }
        .close {
            display: block;
            border-radius: 10px;
            background-color: #bebebe;
            /* color: #fff; */
            padding: 2vw;
            width: 50%;
            font-size: 3vw;
            text-align: center; // border: 1px solid #b2b2b2;
            html.iphonex & {}
        }
        .juhe {
            background: linear-gradient(to left, #23ac38, #23ac38 50%, #00a0e9 50%, #00a0e9);
            max-width: none;
            margin-top: 56vw;
            .qr-inner {
                padding: 0;
                .qr-logo {
                    display: flex;
                    padding: 0;
                    span {
                        display: block;
                        flex: 0 0 50%;
                        height: 5vw;
                        img {
                            margin: auto;
                        }
                    }
                }
            }
        }
        .juhe2 {
            max-width: none;
            margin-top: 56vw;
            flex-direction: row;
            width: 80%;
            justify-content: space-between; // background: linear-gradient(to left, #23ac38, #23ac38 50%, #00a0e9 50%, #00a0e9);
            >div {
                flex: 0 0 45%;
                padding: 25px;
                border-radius: 3vw; // margin: 0 3vw; 
            }
            .qr-inner {
                .qr-box {
                    width: 100%;
                    margin: auto;
                    span {
                        font-size: 3vw;
                        img {
                            margin: auto;
                            height: auto!important;
                        }
                    }
                }
            }
        }
        .wx {
            background-color: #23ac38;
        }
        .ali {
            background-color: #00a0e9;
            .qr {
                >img {
                    width: 80%;
                    margin: auto;
                }
            }
        }
    }
    &.on {
        .qr-img {
            pointer-events: all;
        }
    }
    @media (max-width:500px) {
        html & {
            .inner {
                background-color: rgba(0, 0, 0, 0.7);
                padding: 10% 0;
                h2 {
                    position: relative;
                    bottom: auto;
                }
                .close {
                    @include extendClickArea(20px);
                    @include size(40px);
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin: 20px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    .oi {
                        top: -1px;
                    }
                }
                .juhe2 {
                    flex-direction: column;
                    margin: 0;
                    width: 60%;
                    .qr-inner {
                        margin-bottom: 3vw;
                    }
                }
            }
        }
    }
}

.member-info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 7vw;
    /* background-color: #000; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 2vw;
    &.on {
        position: absolute;
        z-index: 999;
    }
    >div {
        margin-left: 2vw;
    }
    .id {
        font-size: 3vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media (max-width: 500px) {
            max-width: 170px;
        }
    }
    .logout {
        @include size(5vw);
        border-radius: 100%;
        overflow: hidden; // background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        >span {
            color: #454545;
        }
        img {
            height: 3vw;
        }
    }
}

.header {
    .title {
        text-align: center;
        display: block;
        font-size: 1.5rem;
        font-weight: 100;
        padding: 6vw 0;
        img {
            height: 7vw;
            margin: auto;
        }
    }
    @media (max-width: 500px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3vw 3vw;
        background-color: #eeeeee;
        margin-bottom: 2vw;
        >div {
            flex: 0 0 auto;
        }
        .member-info {
            position: relative;
            width: auto;
        }
        .title {
            padding: 0;
            img {
                height: 8vw;
            }
        }
    }
}

.resetForm {
    .form-group {
        span {
            text-align: left;
        }
    }
    .err {
        text-align: left;
        color: red;
        font-size: 0.9rem;
    }
}

.add-title {
  span {
    display: inline-block;
    font-size: .8rem;
  }
}
.ref-wrap{
  padding: 0.5rem 1rem;
  margin: auto;
  input {
    display: block;
    text-align: left;
    width: 100%;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    line-height: 1;
    font-size: 3vw;
    color: black;
    &:hover,&:focus {
      border: 1px solid #23ac38;
    }
  }
}
