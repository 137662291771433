.login {
    background-color: #fff;
    .inner {
        width: 80%;
        margin: auto;
        .title {
            text-align: center;
            display: block;
            font-size: 1.5rem;
            font-weight: 100;
            padding: 6vw 0;
            img {
                height: 7vw;
                margin: auto;
            }
        }
    }
}