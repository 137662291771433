@-webkit-keyframes rotate360 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate360 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes flash {
    from {
        transform: scale(1);
        opacity: 0;
    }
    1% {
        opacity: 1;
    }
    2% {
        opacity: 0;
    }
    10% {
        opacity: 1;
        transform: scale(1.03);
        filter: blur(3px);
    }
    16% {
        opacity: 1;
        transform: scale(1);
        filter: blur(0px);
    }
    50% {
        filter: blur(0px);
    }
    60% {
        filter: blur(3px);
    }
    70% {
        filter: blur(0px);
        transform: scale(1.03);
    }
    to {
        transform: scale(1);
        opacity: .8;
    }
}

@keyframes arrowdown {
    from {
        transform: translate(0, 15%);
    }
    to {
        transform: translate(0, -15%);
    }
}

@keyframes fudong {
    from {
        transform: rotate3d(0, 0, 1, -8deg) translate(0, 0);
    }
    to {
        transform: rotate3d(0, 0, 1, -8deg) translate(0, 5%);
    }
}

@keyframes jinru {
    from {
        transform: translate(0, 0);
    }
    to {
        transform: translate(10%, -10%);
    }
}

@keyframes huadong {
    0% {
        transform: translate(0, 0);
    }
    50% {
        opacity: 1;
        transform: translate(-100%, 0);
    }
    100% {
        opacity: 0;
        transform: translate(-100%, 0);
    }
}

@keyframes breath {
    0% {
        opacity: .5;
        transform: scale(1.1);
    }
    50% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: .8;
        transform: scale(1.1);
    }
}

@keyframes around {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(-360deg);
    }
}

@keyframes hand {
    0% {
        transform: rotate(-180deg);
        opacity: 0;
    }
    25% {
        transform: rotate(-180deg);
        opacity: 1;
    }
    75% {
        transform: rotate(-360deg);
        opacity: 1;
    }
    100% {
        transform: rotate(-360deg);
        opacity: 0;
    }
}

@keyframes hand2 {
    0% {
        transform: rotate(-180deg);
        opacity: 0;
    }
    25% {
        transform: rotate(-180deg);
        opacity: 1;
    }
    75% {
        transform: rotate(0deg);
        opacity: 1;
    }
    100% {
        transform: rotate(0deg);
        opacity: 0;
    }
}

@keyframes hand-arrow {
    0% {}
    25% {
        transform: rotate(0);
    }
    75% {
        transform: rotate(-180deg);
    }
    100% {}
}

@keyframes arrowup {
    0% {
        transform: translate(0, 0);
    }
    100%{
        transform: translate(0, 5%);
    }
}
@keyframes arrowb {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, 100%);
    }
 
}
@keyframes btnbuy {
    from {
        transform: scale(.5);
    }
    to {
        transform: scale(1);
    }
}

@keyframes intro-text {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    40%{
        // transform: translateY(-50px);
        opacity: 1;
    }
    60%{
        // transform: translateY(-50px);
        opacity: 1;
    }
    100%{
        transform: translateY(-100px);
        opacity: 0;
    }
}

@keyframes tada {
    from {
        transform: scale3d(1, 1, 1);
        opacity: 0
    }
    10%,
    20% {
        opacity: 1;
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }
    30%,
    50%,
    70%,
    90% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%,
    60%,
    80% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}