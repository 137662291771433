section.share-qr {
    .header {
        margin: auto;
    }
    .weui-form-preview {
        width: 100%;
    }
    .price {
        strong {
            font-size: 10vw;
        }
        small {
            display: block;
            text-align: center;
        }
        margin-bottom: 20px;
    }
    .qrbox {
        .inner {
            position: relative;
            background-color: transparent;
            .juhe2 {
                width: 50%; // flex-direction: row;
                margin-bottom: 10%;
                .qr-inner {
                    padding: 10px;
                    border-radius: 2vw; // flex: 0 0 49%;
                    .qr-box {
                        padding: 0;
                    }
                    &.ali {
                        .qr-box {
                            padding: 10px;
                        }
                    }
                    .qr-img {
                        pointer-events: all;
                    }
                }
            }
        }
    }
}