.mch {
    .header {
        background-color: transparent; // border-bottom: 4px solid #eee;
        flex-direction: column;
        margin-bottom: 0;
        .title {
            &.no-logo{
                font-weight: bold;
                margin: 40px 0;
            }
        }
        >img {
            height: 100px;
        }
    }
    .pay-btn {
        width: 100%;
    }
    .logo {
        text-align: center;
        height: 20px;
        img {
            margin: auto;
        }
    }
    .paylogo {
        padding: 2vw;
        .inner {
            >div {
                // flex-direction: row;
                align-items: center;
                >span {
                    margin: 0 20px;
                }
            }
        }
    }
}

.modal.mch-fail {
    background: #fff;
}