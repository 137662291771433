.setting {
    background-color: #f8f8f8;
    .btn {
        position: absolute;
        width: 100%;
        bottom: 10px;
        .weui-panel {
            background-color: #f8f8f8;
        }
    }
}

.page__hd {
    padding: 40px;
}

.page__bd {
    padding-bottom: 20px;
}

.page__bd_spacing {
    padding: 0 15px;
}

.page__title {
    text-align: left;
    font-size: 1.4rem;
    font-weight: 400;
}

.page__desc {
    margin-top: 5px;
    color: #888;
    text-align: left;
    font-size: .8rem;
}

.weui-media-box__title,
.weui-select {
    font-size: 1.2rem;
}

.weui-btn {
    font-size: 1rem;
}

.weui-media-box__desc,
.weui-panel__hd {
    font-size: .8rem;
}