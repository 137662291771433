.form {
    input {
        background-color: #f1f1f1;
        line-height: 2;
        font-size: 4vw;
        padding: 0 20px;
    }
    .form-group {
        display: flex;
        flex-direction: column;
        margin: 0 0 5vw;
        span {
            margin-bottom: 2vw;
            font-size: 3vw;
        }
    }
    .btn-box{
        font-size: 3vw;
    }
}