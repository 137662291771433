$black: #000;
$white: #fff;
$green: #3fb837;
@mixin keyboard($key-height) {
    .jk-keyboard {
        height: $key-height * 4;
    }
    .jk-key {
        height: $key-height;
        line-height: $key-height;
        @each $grade in 0,
        1,
        2,
        3 {
            &.jk-height-#{$grade} {
                height: $key-height * ($grade + 1);
                line-height: $key-height * ($grade + 1);
            }
        }
    }
}

.jk-keyboard {
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0.2;
    display: flex;
    flex-wrap: wrap;
    transition: .3s all ease-in-out;
    transform: translate3d(0, 120%, 0);
    background-color: #fafafb;
    &.open {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    html.iphonex & {
        padding-bottom: 30px;
    }
}

.jk-key {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    flex-basis: 33%;
    font-size: 24px;
    font-family: sans-serif;
    color: $black;
    text-align: center;
    height: 8vh;
    max-height: 65px;
    border: solid #dedfe2;
    border-width: 1px 0 0 1px;
    &.submit {
        color: $white;
        background-color: #06bf04;
        &.disabled {
            color: #beeabe;
            background-color: #64d364;
        }
    }
    &.jk-text {
        font-size: 22px;
    }
    &.down {
        background-color: #ededed;
    }
}