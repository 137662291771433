/* z-index */

.modal {
    z-index: 300;
}

header,
footer,
.logo,
.comp>div {
    position: relative;
    z-index: 200;
}

.form,
canvas {
    z-index: 1000;
}

button {
    cursor: pointer;
}

/* 浮层切换动画 */

.modal {
    @include opacity-on();
    @include divPosition();
    .bg {
        @include divPosition();
        background-color: rgba(0, 0, 0, 0.70);
    }
    &.wx-fail {
        background: #fff;
    }
}

body {
    // background-color: #000;
    min-height: 0 !important;
    touch-action: none;
}

html,
body {
    @media (min-width: 768px) {
        font-size: 24px;
    }
}

html,
body,
.main-content {
    position: relative;
    color: #333;
    @include size(100%);
    overflow: hidden;
    margin: auto;
    section {
        @include size(100%);
        position: relative; // top: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;
        overflow: hidden;
    }
    &.view-animate {
        transition-duration: 1s;
        position: absolute;
        left: 0;
        top: 0;
        &.ng-enter {
            opacity: 0; // transform: scale(1.2);
            &.ng-enter-active {
                opacity: 1;
                transform: none;
            }
        }
        &.ng-leave {
            opacity: 1;
            &.ng-leave-active {
                opacity: 0;
            }
        }
    }
}

.weui-msg {
    width: 100%;
}

.scrollable {
    height: auto !important;
    overflow-y: auto;
    overflow-x: hidden;
    body,
    .main-content {
        position: relative !important;
        height: auto !important;
        overflow-y: auto;
        overflow-x: hidden;
        section {
            height: auto !important;
            position: relative;
        }
    }
}

.screen-lock {
    @include divPosition(100%, 100vh);
    @include opacity-on;
    display: flex;
    position: fixed;
    background: #000 no-repeat center center / cover;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 1000;
}

.iScrollLoneScrollbar {
    position: absolute;
    bottom: 2%;
    right: 7%;
    height: 76%;
    width: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    .iScrollIndicator {
        background-color: #f8bd2c;
        border-radius: 10px;
        overflow: hidden;
    }
}

.owl-carousel {
    touch-action: none;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    .logo {
        height: 32px *$imageScale;
    }
    .logo2 {
        height: 19px *$imageScale;
    }
    .music {
        @include extendClickArea(15px);
        width: 30px;
        height: 26px;
        background: url('../assets/images/soundoff.png') no-repeat center top/contain;
        position: absolute;
        top: 10vw;
        left: 86vw; // opacity: .5;
        html.android & {
            right: 50px;
        }
        &.on {
            opacity: 1;
            width: 30px;
            height: 26px;
            background: url('../assets/images/soundon.png') no-repeat center top/contain;
        }
    }
}

.godown {
    bottom: 3%;
    position: absolute;
    width: 100%;
    pointer-events: none;
    >img,
    >span {
        margin: auto;
    }
    img {
        height: 1.5vw;
    }
    span {
        @include size(18px);
        display: block;
        margin-top: 1%;
        animation: arrowdown .5s infinite alternate;
        i {
            @include arrow(#fff,
            #fff,
            35deg);
        }
    }
}

.video {
    // @include opacity-on;
    transition: none;
    width: 100%;
    height: calc(100vw / 16 * 9); // top: 50%;
    // margin-top: calc(-100vw / 16 * 9 / 2);
}

.skip {
    @include extendClickArea(20px);
    position: fixed;
    right: 0;
    bottom: 0;
    width: 60px;
}

.share-box {
    background-color: rgba(0, 0, 0, 0.6);
    .inner {
        position: absolute;
        @include size(100%);
        img {
            max-height: none;
        }
    }
}

.m-loading {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
    opacity: 1;
    transition: all ease .4s;
    &.activity {
        border: solid 2px transparent;
        border-color: #09bb07 #09bb07 #09bb07 transparent;
        border-radius: 100%;
        animation: rotate360 .5s linear infinite;
    }
    &.on {
        opacity: 0;
    }
}

.loading {
    .bg {
        background: none;
    }
    .inner {
        pointer-events: none;
        @include box-center(120px);
        background-color: rgba(255, 255, 255, 0.95);
        border-radius: 10px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
        span {
            position: absolute;
            width: 100%;
            text-align: center;
            font-size: .8rem;
            bottom: 5%;
            line-height: 1;
        }
    }
    .i {
        @include box-center(50px);
        transform: translate(0, -20%);
    }
    &.success,
    &.info,
    &.warning {
        opacity: 1;
        .m-loading {
            opacity: 0;
        }
    }
    &.on {
        .i {
            display: none;
        }
    }
}

.complete {
    background-color: #fff;
    opacity: 1;
    transform: translate(0, 100%);
    &.on {
        transform: translate(0, 0);
    }
    .inner {
        display: flex;
        flex-direction: column;
        padding: 15vw 4vw;
        justify-content: center;
        align-items: center;
        >div {
            margin: 3vw;
        }
        .i {
            width: 20vw;
        }
        .text {
            text-align: center;
            h2 {
                font-size: 5vw;
            }
            span {
                font-size: 3vw;
            }
        }
        .btn-box {
            width: 100%;
            button {
                font-size: 3vw;
            }
        }
    }
}

@media (orientation:portrait) {}

@media (orientation:landscape) {
    .screen-lock {
        opacity: 1;
        pointer-events: all;
        color: #fff;
    }
}

// @font-face {
//     font-family: 'Icons';
//     src: url('../styles/fonts/open-iconic.eot');
//     src: url('../styles/fonts/open-iconic.eot?#iconic-sm') format('embedded-opentype'), url('../styles/fonts/open-iconic.woff') format('woff'), url('../styles/fonts/open-iconic.ttf') format('truetype'), url('../styles/fonts/open-iconic.otf') format('opentype'), url('../styles/fonts/open-iconic.svg#iconic-sm') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }